
import React from 'react';
import axios from 'axios';
import { Card,Avatar} from 'antd';
import { Form, Input, Button,Select,Spin,Image,DatePicker,Result,Switch,Tooltip } from 'antd';
import {
    Col,
    Row,
  } from 'reactstrap';
import { UserOutlined,LoadingOutlined,EditOutlined } from '@ant-design/icons';
import {  LockOutlined,MailOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-number-input'
import CurrencyFormat from 'react-currency-format';
import * as reactstrp from 'reactstrap';

import * as serverconfig from '../../views/serverconn'
import moment from 'moment';
import * as primarycolor from '../../views/primarycolor'

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';


var token= ''
var bizuserid= ''


class NgoDetail extends React.Component{

    state={
        ngo: {},
        ngo_name:'',  
        licence:'',
        company_email:'',
        ngo_logo:null,   
        companyphone:'',
        boxnumber:'',
        accountslimit:0,
        branchname:'',
        companyphonetwo:'',
        companyphonethree:'',
        website:'',
        address:'',
        datarequested:true,  
        safebalance:0,
        sharevalue:0,
        retainedearnings:0,
        packages:[],
        package:'',
        mobilemoneygatewaycode:'',
        mobilemoneyreceiver_number:'',
        bankaccount:'',
        ngo_controller:false

    }


    componentDidMount(){

      
      if(localStorage.getItem("bizuserid")){
        bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      }else{
         bizuserid= ''
      }
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }

        axios.defaults.headers={
          "Content-Type":"application/json",
          Authorization:`Token ${token}`
        }


        const ngoID=this.props.match.params.ngoID;
        axios.get(`${serverconfig.backendserverurl}/api/ngos/${ngoID}`)
        .then(res => {  
            this.setState({ngo: res.data})

            this.setState({ngo_name: res.data.ngo_name})
            this.setState({company_email: res.data.company_email})
            this.setState({companyphone: res.data.RegisteredPhoneNumber1})
            this.setState({boxnumber: res.data.boxnumber})

            this.setState({website: res.data.website})
            this.setState({companyphonetwo: res.data.RegisteredPhoneNumber2})
            this.setState({companyphonethree: res.data.RegisteredPhoneNumber3})
            this.setState({address: res.data.address})
            this.setState({ngo_controller: res.data.ngo_controller})

        })

        this.setState({datarequested:false})

    }

    //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})
    const ngoID=this.props.match.params.ngoID;

    let form_data = new FormData();
    form_data.append('ngo_name', this.state.ngo_name);
    form_data.append('company_email', this.state.company_email);
    form_data.append('RegisteredPhoneNumber1', this.state.companyphone);
    form_data.append('box_number', this.state.boxnumber);

    this.state.ngo_logo==null?
    console.log("No profile file")
    :
    form_data.append('ngo_logo', this.state.ngo_logo,this.state.ngo_logo?this.state.ngo_logo.name:"");
    
    form_data.append('website',this.state.website);
    form_data.append('RegisteredPhoneNumber2', this.state.companyphonetwo);
    form_data.append('RegisteredPhoneNumber3', this.state.companyphonethree);

    form_data.append('ngo_controller', this.state.ngo_controller);
    form_data.append('address', this.state.address);

    axios.put(serverconfig.backendserverurl+`/api/ngos/${ngoID}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>{


  this.setState({datarequested:false})
  this.setState({datasubmittedsuccessfully:true})

  }
    
  )
  .catch(error => console.log(error))

  }


    render(){
        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
      
          }else{

            if (this.state.datasubmittedsuccessfully===true){
              return(
                  <Card>
                  <Result
                  status="success"
                  title="Successfully Updated ngo details "
                  subTitle="ngo details can be updated any time"
                  extra={[
                    <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                  ]}
                  />
                  </Card>
              );
      
            }else{

            return(
            <div>

              <Card> 

                <h4 style={{fontWeight:'bold'}}>Ngo Update Form</h4>
                <Form 
                  onFinish={(event) => this.handleFormSubmit(event)} 

                >
                <FormItem label="NGO Name"
              >
              <Input name="ngo_name"  placeholder="Put a name here." value={this.state.ngo_name} onChange={(val)=>{this.setState({ngo_name:val.target.value})}} />
            </FormItem>
  
  
            <FormItem label="NGO Logo">
              <Input name="ngo_logo" type="file" accept="image/png, image/jpeg" placeholder="NGO logo"  onChange={(val)=>{this.setState({ ngo_logo: val.target.files[0]})}}/>
            </FormItem>  
            
            <FormItem label="NGO Email"
            >
              <Input name="company_email" type="email"  placeholder="company@email.com" value={this.state.company_email} onChange={(val)=>{this.setState({company_email:val.target.value})}} />
            </FormItem> 
  
            <FormItem label="Phone Number">
            <PhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Enter phone number"
                  value={this.state.companyphone} onChange={(val)=>{this.setState({companyphone:val})}}/>
             </FormItem>
             <FormItem label="Phone Number Two">
            <PhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Enter phone number"
                  value={this.state.companyphonetwo} onChange={(val)=>{this.setState({companyphonetwo:val})}}/>
             </FormItem> 
  
             <FormItem label="Phone Number Three">
            <PhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Enter phone number"
                  value={this.state.companyphonethree} onChange={(val)=>{this.setState({companyphonethree:val})}}/>
             </FormItem>  
             <FormItem label="Website"

             >
              <Input  name="website"  placeholder="Webiste here" value={this.state.website} onChange={(val)=>{this.setState({website:val.target.value})}} />
            </FormItem>
  
            <FormItem label="Box number">
              <Input name="boxnumber"  placeholder="Box number here" value={this.state.boxnumber} onChange={(val)=>{this.setState({boxnumber:val.target.value})}} />
            </FormItem>
  
            <FormItem label="Address"
            >
              <Input name="address"  placeholder="Address here" value={this.state.address} onChange={(val)=>{this.setState({address:val.target.value})}} />
            </FormItem>

            <FormItem>
              <Button type="primary" htmlType="submit" >Update</Button>
            </FormItem>
          </Form>

          </Card>
    
          </div>
          )

          }

        }
  }
}

export default NgoDetail; 