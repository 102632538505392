import React from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Form,InputNumber,Modal,Popover,Spin,Popconfirm,message,Tooltip,Switch } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,DollarOutlined,RedoOutlined,FundViewOutlined,LoadingOutlined,MehFilled,PrinterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../../views/serverconn'
import NGOCreateForm from '../components/NGOcreateform'
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
import ReactToPrint from 'react-to-print';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';
import PhoneInput from 'react-phone-number-input'
var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const FormItem=Form.Item;
const dateFormat = 'DD/MM/YYYY';

function callback(key) {
  console.log(key);
}

var token= ''
var bizuserid= ''


class ReceiptToPrint extends React.Component {

  state = {
    companyprofile:{},
    companystatus:false
  };

  componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

   if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
  }else{
      bizuserid= ''
  }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/companyprofile/${1}`)
    .then(res => {  
        this.setState({
          companyprofile:res.data
        })
    })



  }


  render() {
    return (
      <div style={{padding:20}}>
          <Row > 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.company_logo} />
              
                </Col>
                <Col xs="6" sm="6" lg="6">
                <h3>{this.state.companyprofile.company_name}</h3>
                <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                </h6>               
                <h5>Email: {this.state.companyprofile.company_email}</h5>
                <h5>{this.state.companyprofile.box_number}</h5>
                </Col>
           </Row>
          <br></br>
          <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center'}}><strong>{"AVAILABLE SACCOS"}</strong></h3>

          <Table columns={this.props.columns} 
          pagination={{hideOnSinglePage:true,responsive:true}} 
          dataSource={this.props.companymanagers}  
          bordered/>
         
      </div>
    );
  }
}



class NGOList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    ngos: [],
    companymanagers:[],
    datarequested:true,
    walletmodalvisible:false,
    selectedsaccooject:{},
    date:moment().format(dateFormat).toString(),
    saccodebitamount:0,
    mobilephone:'',
    userrights:{},
    userprofile:{},

  };



  componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

   if(localStorage.getItem("bizuserid")){
    bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
        bizuserid= ''
    }



    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    let form_data = new FormData();
    form_data.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
    })


    axios.get(serverconfig.backendserverurl+'/api/ngos/')
    .then(res => {
        this.setState({
          ngos:res.data
        })
        this.setState({datarequested:false})
    })

    axios.get(serverconfig.backendserverurl+`/api/accountrights_ge/?user=${bizuserid}`)
    .then(res => {
        this.setState({
          userrights:res.data[0]
        })
    })



}

  /////////////////////////////////////
  // change company status
  changecompanystatus = (val,record) => {
    console.log(val)
    this.setState({companystatus:val})
    this.setState({datarequested:true})

    //now update the company openness
    let form_data = new FormData();
      form_data.append('activated', val);
      form_data.append('usesms', record.usesms);

      axios.put(`${serverconfig.backendserverurl}/api/saccos/${record.id}/`,form_data, {
          headers: {
          'content-type': 'multipart/form-data'
          }
      })
      .then(res => {
        this.setState({datarequested:false})
        window.location.reload(false)
      })
      .catch(error => console.log(error))

  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };




  render() {
    const columns = [
      
      {
        title: 'Name',
        dataIndex: 'ngo_name',
        key: 'id',
        ...this.getColumnSearchProps('ngo_name'),
      },
      {
        title: 'Office Tel',
        dataIndex: 'RegisteredPhoneNumber1',
        key: 'id',
      },
      {
        title: 'Email',
        dataIndex: 'company_email',
        key: 'id',
      },
      {
        title: 'Controller?',
        dataIndex: 'ngo_controller',
        key: 'id',
        render: (text,record) =><p>{record.ngo_controller===true?"Yes":"No"}</p>
      },
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <div>
        {
          this.state.userprofile.managerial_account===true?
          <p>
          <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
           <Link to={`/ngodetails/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
          </Popover>
          &nbsp;&nbsp;&nbsp;
          <Popover content={<p>Click here to view user accounts</p>} title="Edit,View">
           <Link to={`/ngousers/${text}`}><FundViewOutlined style={{color:'green'}}/></Link>
          </Popover>
          </p>
          :
          <p></p>
        }
        </div>
      },

    ];


    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{

      return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="NGOs" key="1">
            <Table 
            columns={columns}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.ngos}
            scroll={{ x: 1000 }}
            bordered/>
            </Panel>
            </Collapse>
            <br />

{ /*            <Row > 
              <Col xs="12" sm="6" lg="6">
                <Collapse defaultActiveKey={['0']} onChange={callback}>
                <Panel header="Collapse Panel to open NGO creation Form" key="1">
                  <h2>Create NGO here</h2>
                  <NGOCreateForm />
                </Panel>
                </Collapse>

              </Col>
                       
          </Row> **/}

        </div>
    )

    }

  }
}

export default NGOList; 

