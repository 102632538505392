import React from 'react';
import axios from 'axios'
import { Table,Select,Typography, Input, Button,Collapse,Form,InputNumber,Modal,Popover,Spin,Popconfirm,message,Tooltip,Switch,DatePicker } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,DollarOutlined,RedoOutlined,FundViewOutlined,LoadingOutlined,MehFilled,PrinterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../../views/serverconn'
import TransactionCreateForm from '../components/Transactioncreateform'
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
import ReactToPrint from 'react-to-print';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';
import PhoneInput from 'react-phone-number-input'

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const FormItem=Form.Item;
const dateFormat = 'DD/MM/YYYY';

const { Option } = Select;
const { Title } = Typography;
const { RangePicker } = DatePicker;

function callback(key) {
  console.log(key);
}

var token= ''



class ReportToPrint extends React.Component {

  state = {
    companyprofile:{},
  };

  componentDidMount(){
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/sponsor/${1}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })


  }

  render() {
    return (
      <div style={{padding:20}}>
        <Row> 
              <Col xs="2" sm="2" lg="2">
              <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.company_logo} />
            
                </Col>
                <Col xs="6" sm="6" lg="6">
                <h3>{this.state.companyprofile.company_name}</h3>
                <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                {
                this.state.companyprofile.RegisteredPhoneNumber2==""?
                "":","+this.state.companyprofile.RegisteredPhoneNumber2
                }
                {
                this.state.companyprofile.RegisteredPhoneNumber3==""?
                "":","+this.state.companyprofile.RegisteredPhoneNumber3
                }
                </h6>               
                <h5>Email: {this.state.companyprofile.company_email}</h5>
                <h5>Wesite: {this.state.companyprofile.website}</h5>
                <h5>{this.state.companyprofile.box_number}</h5>
              
                </Col>
       </Row>

           <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>NGO TO NGO TRANSACTIONS REPORT FROM: {this.props.dateone}  TO: {this.props.datetwo}</h3>


             <reactstrp.Table bordered>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Account to</th>
                        <th>Transaction Id</th>
                        <th>Ngo from</th>
                        <th>Ngo to</th>
                        <th>Currency</th>
                        <th>Batch number</th>
                        <th>Amount</th>
                        <th>Charge</th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.props.sheetitems.map(
                      (item)=>(
                        <tr>
                        <td>{item.date}</td>
                        <td>{item.accountnumber_to}</td>
                        <td>{item.transactionID}</td>
                        <td>{item.ngofrom_name}</td>
                        <td>{item.ngoto_name}</td>
                        <td>{item.currency}</td>
                        <td>{item.batch_number}</td>
                        <td>{<CurrencyFormat value={item.Amount} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td>{<CurrencyFormat value={item.transaction_charge} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>
                      ))}
                      <tr>
                      <td style={{fontWeight:'bolder'}}>Total</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.total} displayType={'text'} thousandSeparator={true}/>}</td>
                      <td></td>
                      </tr>
                  </tbody>
              </reactstrp.Table> 
      </div>
    );
  }
}



class Ngo_NgoTransactionsReport extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    transactions: [],
    companymanagers:[],
    datarequested:true,
    walletmodalvisible:false,
    selectedsaccooject:{},
    date:moment().format(dateFormat).toString(),
    saccodebitamount:0,
    mobilephone:'',

    dateone:'',
    datetwo:'',
    channeltype:'',

  };


  componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    this.setState({datarequested:false})

}


  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  calculatetotalamount=()=>{
     var tot=0
    this.state.transactions.map((item)=>{
      tot+=Number(item.Amount)
    })
    return tot;
  }


  searchSales=()=>{

    let form_data = new FormData();
    form_data.append('dateone', this.state.dateone);
    form_data.append('datetwo', this.state.datetwo);
    form_data.append('channeltype', this.state.channeltype);

    if(this.state.dateone===''||this.state.datetwo===''||this.state.channeltype===''){
      alert("Date or channel type missing ")
    }else{
        this.setState({datarequested:true})
        this.setState({transactions:[]})

        //Now submit sale data to database
        axios.post(serverconfig.backendserverurl+'/customqueries/get_ngo_ngotransactions', form_data,{
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
        .then(res =>{
          this.setState({datarequested:false})
          this.setState({transactions:JSON.parse(res.data.report)})
        } 
        )
        .catch(error => console.log(error))   
    }

    }



  render() {
    const columns = [
      
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'id',
        ...this.getColumnSearchProps('date'),
      },
      {
        title: 'Amount',
        dataIndex: 'Amount',
        key: 'id',
        render: (text,record) =><p><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}/></p>
      },

      {
        title: 'Account to',
        dataIndex: 'accountnumber_to',
        key: 'id',
        ...this.getColumnSearchProps('accountnumber_to'),
      },
      {
        title: 'Transaction Id',
        dataIndex: 'transactionID',
        key: 'id',
        ...this.getColumnSearchProps('transactionID'),
      },
      {
        title: 'Ngo to',
        dataIndex: 'ngoto_name',
        key: 'id',
        ...this.getColumnSearchProps('ngoto_name'),
      },
      {
        title: 'Ngo from',
        dataIndex: 'ngofrom_name',
        key: 'id',
        ...this.getColumnSearchProps('ngofrom_name'),
      },
      {
        title: 'Currency',
        dataIndex: 'currency',
        key: 'id',
        ...this.getColumnSearchProps('currency'),
      },
      {
        title: 'Batch Number',
        dataIndex: 'batch_number',
        key: 'id',
        ...this.getColumnSearchProps('batch_number'),
      },
      {
        title: 'Charge',
        dataIndex: 'transaction_charge',
        key: 'id',
        render: (text,record) =><p><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}/></p>
      },
    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{

      return(
        <div>

            <div style={{display: "none"}}>
               &nbsp;&nbsp;
              <ReportToPrint
              dateone={this.state.dateone}
              datetwo={this.state.datetwo}
              sheetitems={this.state.transactions}
              total={this.calculatetotalamount()}
              ref={el => (this.componentRef = el)} /> 
            </div>


            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="NGO TO NGO TRANSACTIONS" key="1">
            <Form  layout="inline" >

            <FormItem label="Channel type">
                     <Select 
                     placeholder="Channel type"
                     style={{ width: 200 }} 
                     value={this.state.channeltype} 
                     onChange={(val)=>{this.setState({channeltype:val})}}
                      
                     showSearch
                     optionFilterProp="children"
                     onFocus={this.onFocus}
                     onBlur={this.onBlur}
                     onSearch={this.onSearch}
                     filterOption={(input, option) =>
                       option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                     }                     
                      >
                        <Option value={'mobile'}>Mobile</Option>
                        <Option value={'bank'}>Bank</Option>
                        <Option value={'cash'}>Cash</Option>

                     </Select>
                 </FormItem>


              <FormItem label="Date Range">
                  <RangePicker defaultValue={moment()} 
                  onChange={(date, dateString) =>{
                    this.setState({ dateone: dateString[0]});
                    this.setState({ datetwo: dateString[1]});
                  } } format={dateFormat} />
              </FormItem>

              <FormItem>
              <Button onClick={this.searchSales} type="primary" htmlType="button">Search</Button>
              </FormItem> 

             </Form>          
            
            
            <Table 
            columns={columns}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.transactions}
            scroll={{ x: 1000 }}
            bordered/>

          <br></br>
          <h3>Total: <CurrencyFormat value={this.calculatetotalamount()} displayType={'text'} thousandSeparator={true}/></h3>


            </Panel>
            </Collapse>
            <br />

            <div style={{display:'flex',justifyContent:'flex-end',alignSelf:'flex-end',alignItems:'flex-end'}}>
              <ReactToPrint
                trigger={() => 
                <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                Print
                </Button>
                }
                content={() => this.componentRef}
                />
            </div>
        </div>
    )}

  }
}

export default Ngo_NgoTransactionsReport; 

