import React from 'react';
import { Route } from 'react-router-dom';
import AdminIndex from './SiteAdmin/containers/adminindex'
import NGOList from './SiteAdmin/containers/ngoslist'
import SaccoDetail from './SiteAdmin/containers/saccoDetailView'
import NGOUsers from './SiteAdmin/containers/ngousers'
import NGOUserAccountDetail from './SiteAdmin/containers/ngouseraccountdetailview'
import Saccowalletstatementreport from './SiteAdmin/containers/saccowalletstatementreport'
import SaccoSmsUsagestatementreport from './SiteAdmin/containers/saccosmsusagestatementreport'
import PackageDetail from './SiteAdmin/containers/PackageDetailView'
import ApplicationDetail from './SiteAdmin/containers/ApplicationDetailView'

import PackageList from './SiteAdmin/containers/packageslist'
import TransactionList from './SiteAdmin/containers/transactionlist'
import AllUsers from './SiteAdmin/containers/allusers'
import EdenNgoTransactionsReport from './SiteAdmin/containers/transaction_edenngoreport'
import Ngo_NgoTransactionsReport from './SiteAdmin/containers/transaction_ngo_ngoreport'
import ChildrenReport from './SiteAdmin/containers/childrenreport '
import ChildDetail from './SiteAdmin/containers/ChildDetailView'
import ChildDocumentsList from './SiteAdmin/containers/ChilddocumentsView'
import AccountDetail from './SiteAdmin/containers/AccountDetailView'
import NgoDetail from './SiteAdmin/containers/NgoDetailView'
import Expensereport from './SiteAdmin/containers/Expensereport'
import GuardiansReport from './SiteAdmin/containers/guardiansreport'
import GuardianDetail from './SiteAdmin/containers/GuardianDetailView'
import guardian_children from './SiteAdmin/containers/guardian_children'
import FeesList from './SiteAdmin/containers/SchoolFeesListView'
import FeesPaymentList from './SiteAdmin/containers/SchoolFeesPaymentListView'
import ChilddevelopmentreportList from './SiteAdmin/containers/ChilddevelopmentreportsView'
import SchoolsReport from './SiteAdmin/containers/schoolsreport'

const AdminBaseRouter = () =>(
    <div>
        <Route exact path='/admin/' component={AdminIndex} />
        <Route exact path='/' component={AdminIndex}  />
        <Route exact path='/ngos/' component={NGOList}  />
        <Route exact path='/saccos/:saccoId' component={SaccoDetail}  />
        <Route exact path='/ngousers/:ngoId' component={NGOUsers}  />
        <Route exact path='/ngousersdetails/:userid' component={NGOUserAccountDetail}  />
        <Route exact path='/vendorwalletstatement/' component={Saccowalletstatementreport}  />
        <Route exact path='/smsusagestatement/' component={SaccoSmsUsagestatementreport}  />
        <Route exact path='/packages/' component={PackageList}  />
        <Route exact path='/packages/:packageId' component={PackageDetail}  />
        <Route exact path='/applicationdetails/' component={ApplicationDetail}  />
        <Route exact path='/transactions/' component={TransactionList}  />
        <Route exact path='/allusers/' component={AllUsers}  />
        <Route exact path='/userdetails/:accountID' component={AccountDetail}  />

        <Route exact path='/edenngotransactionsreport/' component={EdenNgoTransactionsReport}  />
        <Route exact path='/ngongotransacyionsreport/' component={Ngo_NgoTransactionsReport}  />
        <Route exact path='/childrensreport/' component={ChildrenReport}  />
        <Route exact path='/children/:childID' component={ChildDetail}  />
        <Route exact path='/childdocuments/:childID' component={ChildDocumentsList}  />
        <Route exact path='/ngodetails/:ngoID' component={NgoDetail}  />
        <Route exact path='/expensesreport/' component={Expensereport}  />
        <Route exact path='/guardiansreport/' component={GuardiansReport}  />
        <Route exact path='/guardians/:guardianID' component={GuardianDetail}  />
        <Route exact path='/guardianchildren/:guardianID' component={guardian_children}  />
        <Route exact path='/schoolfeesreport/' component={FeesList}  />
        <Route exact path='/schoolfeespayments/:feesID' component={FeesPaymentList} />
        <Route exact path='/childdevelopmentreports/:childID' component={ChilddevelopmentreportList}  />
        <Route exact path='/schools_report/' component={SchoolsReport}  />

    </div>
);

export default AdminBaseRouter;


