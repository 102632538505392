import React,{useState,useEffect,lazy} from 'react';
import axios from 'axios'
import {  Slider,Divider,Statistic,Collapse,Avatar,Tooltip,Button } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined,CreditCardFilled,MessageFilled,LoadingOutlined,
   UserOutlined, AccountBookOutlined,SlidersFilled,MinusCircleFilled,BankFilled,DropboxOutlined,
   DollarCircleOutlined,GroupOutlined,FileFilled,DollarCircleFilled,MinusSquareFilled,FundFilled,PlusSquareFilled,ApartmentOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Line,Pie,Bar } from 'react-chartjs-2';
import {withGetScreen} from 'react-getscreen'
import {
  Col,
  Row,
} from 'reactstrap';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities'
import * as serverconfig from '../../views/serverconn'
import CurrencyFormat from 'react-currency-format';
import * as reactstrp from 'reactstrap';
import { NavLink } from 'react-router-dom'
import { Table, Input,Popover,Modal,Tabs
  ,Card,Form,Select,InputNumber,Drawer,Descriptions,Popconfirm,message,Spin } from 'antd';
import NGOCreateForm from '../components/NGOcreateform'
import Packagecreateform from '../components/packagecreateform'
import SendEmailForm from '../components/SendEmailForm'

import { Link } from 'react-router-dom';
var CryptoJS = require("crypto-js");


const brandPrimary = getStyle('--primary')
const brandSuccess = getStyle('--success')
const brandInfo = getStyle('--info')
const brandWarning = getStyle('--warning')
const brandDanger = getStyle('--danger')

const style = { background: '#0092ff', padding: '8px 0',borderRadius: '7px',justifyContent:'center', alignItems:'center',display: 'flex', };
const dateFormat = 'DD/MM/YYYY';
const yeardateFormat = 'YYYY';
const monthdateFormat = 'MM/YYYY';

const { Panel } = Collapse;
const { Meta } = Card;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const FormItem=Form.Item;
const { Option } = Select;

var token= ''
var bizuserid= ''

class AdminIndex extends React.Component {

  state = {
    expenses:[],
    searchText: '',
    searchedColumn: '',
    date:moment().format(dateFormat).toString(),
    yearlymonths:[],
    yearlymonthlysales:[],
    totalnoofngos:0,
    monthlyinvoicetotalamount:0,
    showprintermodal:false,
    invoicesbycompany:[],
    companies:[],
    invoicetotals:[],
    licencedcompanies:[],
    companylicences:[],
    companyprofile:{},
    datarequested:true,
    totalnoofpackages:0,
    showpackagecreatemodal:false,
    emailmodalvisible:false,

    pendingmobiletransactions:[],
    successfulmobiletransactions:[],
    pendingbanktransactions:[],
    successfulbanktransactions:[],

    pendingtransactions:[],
    successfultransactions:[],

    children:[],
    children_male:[],
    children_female:[],
    guardians:[],
    schools:[],
    userrights:{},

    children_totals_obj:{},
    selectedngo:'',
    selectedngo_name:'',
    ngos:[],
    currentyearmonths:[],
    selected_month:moment().format(monthdateFormat).toString(),
    charts_guardians:[],
    children_totals_list:[],
    children_total_wthreports_list:[],
    totalchildren_wthnoreports_list:[],

    ngos_array:[],
    ngoexpense_list:[],
    currentyearmonths_expenses:[],
    selected_month_ofexpenses:moment().format(monthdateFormat).toString(),

    selected_schooterm_ngo:'',
    selected_schooterm_ngo_name:'',
    selectedschool_term:'',
    selectedschool_term_name:'',

    schoolterms:[],
    schoolfeees_object:{}

  };


  callback =(key) =>{
    console.log(key);
    console.log(this.state.yearlymonthlysales)
  }

  componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

   if(localStorage.getItem("bizuserid")){
    bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
  }else{
      bizuserid= ''
  }

    console.log("token: "+token)

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/ngos/`)
    .then(res => {
        this.setState({
          totalnoofngos:res.data.length
        })

        if (res.data.length>0){
          var ngo_id=res.data[0].id
          this.setState({selectedngo:res.data[0].id})

          axios.get(serverconfig.backendserverurl+`/api/ngos/${res.data[0].id}`)
          .then(res => {
            this.setState({selectedngo_name:res.data.ngo_name})
            this.setState({selected_schooterm_ngo_name:res.data.ngo_name})
          })


          this.setState({selected_schooterm_ngo:res.data[0].id})

          axios.get(serverconfig.backendserverurl+`/api/schoolterms/?Ngo=${res.data[0].id}`)
          .then(res => {
              this.setState({
                schoolterms:res.data
              })

              if(res.data.length>0){
                //get school terms 
                let form_data2 = new FormData();
                form_data2.append('ngoid', ngo_id);
                form_data2.append('term_id', res.data[0].id);
  
                axios.post(serverconfig.backendserverurl+`/customqueries/getfees_statistics`, form_data2,{
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                  })
                .then(res => {
                  this.setState({schoolfeees_object:res.data})
                })

                this.setState({selectedschool_term:res.data[0].id})

                axios.get(serverconfig.backendserverurl+`/api/schoolterms/${res.data[0].id}`)
                .then(res => {
                  this.setState({selectedschool_term_name:res.data.term_name})
                })
              }

          })
          
        }

        this.setState({ngos:res.data})
    })

    axios.get(serverconfig.backendserverurl+`/api/transactions/?status=${"pending"}`)
    .then(res => {
        this.setState({
          pendingtransactions:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/transactions/?status=${"success"}`)
    .then(res => {
        this.setState({
          successfultransactions:res.data
        })
    })


    axios.get(serverconfig.backendserverurl+`/api/mobiletransactions/?status=${"pending"}`)
    .then(res => {
        this.setState({
          pendingmobiletransactions:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/mobiletransactions/?status=${"success"}`)
    .then(res => {
        this.setState({
          successfulmobiletransactions:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/banktransactions/?status=${"pending"}`)
    .then(res => {
        this.setState({
          pendingbanktransactions:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/banktransactions/?status=${"success"}`)
    .then(res => {
        this.setState({
          successfulbanktransactions:res.data
        })
    })
    
    axios.get(serverconfig.backendserverurl+`/api/schools/`)
    .then(res => {
        this.setState({
          schools:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/guardians/`)
    .then(res => {
        this.setState({
          guardians:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/children/`)
    .then(res => {
        this.setState({
          children:res.data
        })
    })


    axios.get(serverconfig.backendserverurl+`/api/children/?gender=${'Male'}`)
    .then(res => {
        this.setState({
          children_male:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/children/?gender=${'Female'}`)
    .then(res => {
        this.setState({
          children_female:res.data
        })
    })


    axios.get(serverconfig.backendserverurl+`/api/accountrights_ge/?user=${bizuserid}`)
    .then(res => {
        this.setState({
          userrights:res.data[0]
        })
    })


    //get the company profile here
    axios.get(`${serverconfig.backendserverurl}/api/sponsor/${1}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

        this.setState({datarequested:false})
    })


    let form_data = new FormData();
    axios.post(serverconfig.backendserverurl+`/customqueries/getchildrenschooltypetotals`, form_data,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          children_totals_obj:JSON.parse(res.data.report) 
        })
    })    


    //get guardian statistics
    let form_data1 = new FormData();
    form_data1.append('action', 'firstdata');

    axios.post(serverconfig.backendserverurl+`/customqueries/getchilddevreportstatistics`, form_data1,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        console.log(JSON.parse(res.data.months))
        this.setState({
          currentyearmonths:JSON.parse(res.data.months) 
        })
        
        var array_list=JSON.parse(res.data.report)

        var gdnlist=[]
        var gdnlist_tots=[]
        var gdnlist_tots_rpt=[]
        var gdnlist_tots_nrpt=[]

        //loop through list
        array_list.map((item)=>{
          gdnlist.push(item.guardian_name)
          gdnlist_tots.push(item.totalchildren)
          gdnlist_tots_rpt.push(item.totalchildren_wthreports)
          gdnlist_tots_nrpt.push(item.totalchildren_wthnoreports)

        })

        this.setState({charts_guardians:gdnlist})

        this.setState({children_totals_list:gdnlist_tots})
        this.setState({children_total_wthreports_list:gdnlist_tots_rpt})
        this.setState({totalchildren_wthnoreports_list:gdnlist_tots_nrpt})

    })
    
    //get expenses statistcis
   
    let form_data2 = new FormData();
    form_data2.append('action', 'firstdata');

    axios.post(serverconfig.backendserverurl+`/customqueries/getexpense_statistics`, form_data2,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        console.log(JSON.parse(res.data.months))
        this.setState({
          currentyearmonths_expenses:JSON.parse(res.data.months) 
        })
        
        var array_list=JSON.parse(res.data.report)
        var ng_list=[]
        var exp_list=[]

        //loop through list
        array_list.map((item)=>{
          ng_list.push(item.ngo_name)
          exp_list.push(item.totalof_expenses)
        })

        this.setState({ngos_array:ng_list})
        this.setState({ngoexpense_list:exp_list})

    })
}



render() {

  const transchartdata = {
    labels: this.state.charts_guardians,
    datasets: [
      {
        label: 'Total children',
        data: this.state.children_totals_list,
        fill: false,
        backgroundColor: 'blue',
        borderColor: 'blue',
        yAxisID: 'y-axis-1',
      },
      {
        label: 'Total children with reports',
        data: this.state.children_total_wthreports_list,
        fill: false,
        backgroundColor: 'green',
        borderColor: 'green',
        yAxisID: 'y-axis-2',
      },
      {
        label: 'Total children without reports',
        data: this.state.totalchildren_wthnoreports_list,
        fill: false,
        backgroundColor: 'red',
        borderColor: 'red',
        yAxisID: 'y-axis-2',
      },
    ],
  };


  const transchartdata_expenses = {
    labels: this.state.ngos_array,
    datasets: [
      {
        label: 'Expense amount',
        data: this.state.ngoexpense_list,
        fill: false,
        backgroundColor:[
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 1,
      yAxisID: 'y-axis-1',
      },
    ],
  };


  const transchartoptions = {
    scales: {
      yAxes: [
        {
          type: 'linear',
          display: true,
          position: 'left',
          id: 'y-axis-1',
        },
        {
          type: 'linear',
          display: true,
          position: 'right',
          id: 'y-axis-2',
          gridLines: {
            drawOnArea: false,
          },
        },
      ],
    },
  };

  
  if(this.state.datarequested===true){
    return(
      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
      <Spin indicator={antIcon} />
      </div>
    )

  }else{

    return(
      
      <div className="animated fadeIn">

      {
      this.state.userrights.can_view_statistics===true?
      <div>
      <Row>

<Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
<NavLink to='/ngos/'> 
<Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
     <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
     
     <BankFilled style={{ fontSize: 24 }}  />
      <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.totalnoofngos}</h6>
      <h6 style={{display:'flex',marginRight:5,fontSize:16}}> Total of Ngos </h6>

     </div>
   </Card>
</NavLink>
 </Col>



 <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
 <NavLink to='/childrensreport/'> 
 <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
     <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
     
     <UserOutlined style={{ fontSize: 24 }}  />
      <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.children.length}</h6>
      <h6 style={{display:'flex',marginRight:5,fontSize:16}}>  Total of children </h6>

     </div>
   </Card>
 </NavLink>

 </Col>

 <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
 <NavLink to='/childrensreport/'> 
 <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
     <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
     
     <UserOutlined style={{ fontSize: 24 }}  />
      <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.children_male.length}</h6>
      <h6 style={{display:'flex',marginRight:5,fontSize:16}}>  Total of male children </h6>

     </div>
   </Card>
 </NavLink>

 </Col>

 <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
 <NavLink to='/childrensreport/'> 
 <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
     <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
     
     <UserOutlined style={{ fontSize: 24 }}  />
      <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.children_female.length}</h6>
      <h6 style={{display:'flex',marginRight:5,fontSize:16}}>  Total of female children </h6>

     </div>
   </Card>
 </NavLink>

 </Col>


 <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
 <NavLink to='/guardiansreport/'>
 <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
     <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
     
     <UserOutlined style={{ fontSize: 24 }}  />
      <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.guardians.length}</h6>
      <h6 style={{display:'flex',marginRight:5,fontSize:16}}>  Total of guardians </h6>

     </div>
   </Card>
 </NavLink>

 </Col>

<Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
<NavLink to='/schools_report/'> 

<Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
     <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
     
     <BankFilled style={{ fontSize: 24 }}  />
      <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.schools.length}</h6>
      <h6 style={{display:'flex',marginRight:5,fontSize:16}}> Total of schools </h6>

     </div>
   </Card>
   </NavLink>

</Col>

<Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
<NavLink to='/childrensreport/'> 

<Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
     <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
     
     <UserOutlined style={{ fontSize: 24 }}  />
      <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.children_totals_obj.totalkindergarten}</h6>
      <h6 style={{display:'flex',marginRight:5,fontSize:16}}> Total children in kindergarten </h6>

     </div>
   </Card>
 </NavLink>

</Col>

<Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
<NavLink to='/childrensreport/'> 

<Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
     <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
     
     <UserOutlined style={{ fontSize: 24 }}  />
      <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.children_totals_obj.totalprimary}</h6>
      <h6 style={{display:'flex',marginRight:5,fontSize:16}}> Total children in primary </h6>

     </div>
   </Card>
   </NavLink>

</Col>

<Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
<NavLink to='/childrensreport/'> 

<Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
     <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
     
     <UserOutlined style={{ fontSize: 24 }}  />
      <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.children_totals_obj.totalsecondary}</h6>
      <h6 style={{display:'flex',marginRight:5,fontSize:16}}> Total children in secondary </h6>

     </div>
   </Card>
   </NavLink>

</Col>

<Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
<NavLink to='/childrensreport/'> 

<Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
     <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
     
     <UserOutlined style={{ fontSize: 24 }}  />
      <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.children_totals_obj.totaluniversity}</h6>
      <h6 style={{display:'flex',marginRight:5,fontSize:16}}> Total children in university </h6>

     </div>
   </Card>
   </NavLink>

</Col>

<Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
<NavLink to='/childrensreport/'> 

<Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
     <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
     
     <UserOutlined style={{ fontSize: 24 }}  />
      <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.children_totals_obj.totalcollege}</h6>
      <h6 style={{display:'flex',marginRight:5,fontSize:16}}> Total children in college </h6>

     </div>
   </Card>
   </NavLink>

</Col>



 <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
 <NavLink to='/edenngotransactionsreport/'>
 <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
     <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
     <DollarCircleFilled style={{ fontSize: 24,color:'orange' }}  />

      <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.pendingtransactions.length}</h6>
      <h6 style={{display:'flex',marginRight:5,fontSize:16}}>  Pending eden-ngo transactions </h6>

     </div>
   </Card>
 </NavLink>

 </Col>

 <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
 <NavLink to='/edenngotransactionsreport/'>

     <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
     <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
      <DollarCircleFilled style={{ fontSize: 24,color:'green' }}  />

      <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.successfultransactions.length}</h6>
      <h6 style={{display:'flex',marginRight:5,fontSize:16}}>  Successful eden-ngo transactions </h6>

     </div>
   </Card>
   </NavLink>

 </Col>


 <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
 <NavLink to='/ngongotransacyionsreport/'>
 <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
     <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
      <DollarCircleFilled style={{ fontSize: 24,color:'orange'}}  />
      <h6 style={{display:'flex',fontSize:18,fontWeight:'bold'}}>mobile money {this.state.pendingmobiletransactions.length},   bank {this.state.pendingbanktransactions.length}</h6>

      <h6 style={{display:'flex',marginRight:5,fontSize:16}}>  Pending ngo-ngo transactions </h6>
    
     </div>
   </Card>

 </NavLink>

 </Col>


 <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
 <NavLink to='/ngongotransacyionsreport/'>
 <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
     <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
      <DollarCircleFilled style={{ fontSize: 24,color:'green' }}  />
      <h6 style={{display:'flex',fontSize:18,fontWeight:'bold'}}>mobile money {this.state.successfulmobiletransactions.length},  bank {this.state.successfulbanktransactions.length}</h6>

      <h6 style={{display:'flex',marginRight:5,fontSize:16}}>  Successful ngo-ngo transactions </h6>

     </div>
   </Card>
 </NavLink>
 </Col>
   </Row>
    <br></br>
    <Row>

    <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>

      <Card >
      <h4 style={{color:'green',display:'flex',alignItems:'center',alignSelf:'center'}}>{this.state.selectedngo_name} guardians monthly total children development reports statistics for {this.state.selected_month}</h4>


        <FormItem label="Ngo">
            <Select 
            placeholder="select Ngo"
            style={{ width: 200 }} 
            value={this.state.selectedngo} 
            onChange={(val)=>{

              axios.get(serverconfig.backendserverurl+`/api/ngos/${val}`)
              .then(res => {
                this.setState({selectedngo_name:res.data.ngo_name})
              })

              this.setState({selectedngo:val})

            //qry and get new data
            let form_data1 = new FormData();
            form_data1.append('action', 'newdata');
            form_data1.append('month', this.state.selected_month);
            form_data1.append('ngo', val);

            axios.post(serverconfig.backendserverurl+`/customqueries/getchilddevreportstatistics`, form_data1,{
              headers: {
                'content-type': 'multipart/form-data'
              }
              })
            .then(res => {
                console.log(JSON.parse(res.data.months))
                this.setState({
                  currentyearmonths:JSON.parse(res.data.months) 
                })
                
                var array_list=JSON.parse(res.data.report)
        
                var gdnlist=[]
                var gdnlist_tots=[]
                var gdnlist_tots_rpt=[]
                var gdnlist_tots_nrpt=[]
        
                //loop through list
                array_list.map((item)=>{
                  gdnlist.push(item.guardian_name)
                  gdnlist_tots.push(item.totalchildren)
                  gdnlist_tots_rpt.push(item.totalchildren_wthreports)
                  gdnlist_tots_nrpt.push(item.totalchildren_wthnoreports)
        
                })
        
                this.setState({charts_guardians:gdnlist})
        
                this.setState({children_totals_list:gdnlist_tots})
                this.setState({children_total_wthreports_list:gdnlist_tots_rpt})
                this.setState({totalchildren_wthnoreports_list:gdnlist_tots_nrpt})
        
            })


          }}

            showSearch
            optionFilterProp="children"
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onSearch={this.onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }                     
            >
              {
                this.state.ngos.map((item)=>{
                  return (<Option value={item.id}>{item.ngo_name}</Option>);
                })
              }
              
            </Select>
        </FormItem>

        <FormItem label="Month">
            <Select 
            placeholder="select Month"
            style={{ width: 200 }} 
            value={this.state.selected_month} 
            onChange={(val)=>{
            this.setState({selected_month:val})
        
            //qry and get new data
            let form_data1 = new FormData();
            form_data1.append('action', 'newdata');
            form_data1.append('month', val);
            form_data1.append('ngo', this.state.selectedngo);

            axios.post(serverconfig.backendserverurl+`/customqueries/getchilddevreportstatistics`, form_data1,{
              headers: {
                'content-type': 'multipart/form-data'
              }
              })
            .then(res => {
                console.log(JSON.parse(res.data.months))
                this.setState({
                  currentyearmonths:JSON.parse(res.data.months) 
                })
                
                var array_list=JSON.parse(res.data.report)
        
                var gdnlist=[]
                var gdnlist_tots=[]
                var gdnlist_tots_rpt=[]
                var gdnlist_tots_nrpt=[]
        
                //loop through list
                array_list.map((item)=>{
                  gdnlist.push(item.guardian_name)
                  gdnlist_tots.push(item.totalchildren)
                  gdnlist_tots_rpt.push(item.totalchildren_wthreports)
                  gdnlist_tots_nrpt.push(item.totalchildren_wthnoreports)
        
                })
        
                this.setState({charts_guardians:gdnlist})
        
                this.setState({children_totals_list:gdnlist_tots})
                this.setState({children_total_wthreports_list:gdnlist_tots_rpt})
                this.setState({totalchildren_wthnoreports_list:gdnlist_tots_nrpt})
        
            })
        
        
          }}

            showSearch
            optionFilterProp="children"
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onSearch={this.onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }                     
            >
              {
                this.state.currentyearmonths.map((item)=>{
                  return (<Option value={item}>{item}</Option>);
                })
              }
              
            </Select>
        </FormItem>
        
        <Line data={transchartdata} options={transchartoptions} />
        </Card>

    </Col>

    <Col xs="12" sm="6" lg="6" style={{padding:'5px'}}>
    <Card >
      <h3 style={{color:'green'}}>School fees budget statistics</h3>

    <FormItem label="Ngo">
            <Select 
            placeholder="select Ngo"
            style={{ width: 200 }} 
            value={this.state.selected_schooterm_ngo} 
            onChange={(val)=>{

              axios.get(serverconfig.backendserverurl+`/api/ngos/${val}`)
              .then(res => {
                this.setState({selected_schooterm_ngo_name:res.data.ngo_name})
              })

              this.setState({selected_schooterm_ngo:val})

          }}

            showSearch
            optionFilterProp="children"
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onSearch={this.onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }                     
            >
              {
                this.state.ngos.map((item)=>{
                  return (<Option value={item.id}>{item.ngo_name}</Option>);
                })
              }
              
            </Select>
        </FormItem>

        <FormItem label="School term">
            <Select 
            placeholder="select term"
            style={{ width: 200 }} 
            value={this.state.selectedschool_term} 
            onChange={(val)=>{
              this.setState({selectedschool_term:val})

              axios.get(serverconfig.backendserverurl+`/api/schoolterms/${val}`)
              .then(res => {
                this.setState({selectedschool_term_name:res.data.term_name})
              })

              let form_data2 = new FormData();
              form_data2.append('ngoid', this.state.selected_schooterm_ngo);
              form_data2.append('term_id', val);

              axios.post(serverconfig.backendserverurl+`/customqueries/getfees_statistics`, form_data2,{
                headers: {
                  'content-type': 'multipart/form-data'
                }
                })
              .then(res => {
                this.setState({schoolfeees_object:res.data})
              })

          }}

            showSearch
            optionFilterProp="children"
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onSearch={this.onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }                     
            >
              {
                this.state.schoolterms.map((item)=>{
                  return (<Option value={item.id}>{item.term_name}</Option>);
                })
              }
              
            </Select>
        </FormItem>
        <br></br>

        {
          JSON.stringify(this.state.schoolfeees_object) === '{}'?
          null:
          <h5 style={{color:'black'}}>
          {this.state.selected_schooterm_ngo_name}
          &nbsp;
          {this.state.selectedschool_term_name}
          &nbsp;
          total school fees:
          &nbsp; 
          <CurrencyFormat value={this.state.schoolfeees_object.totalfees} displayType={'text'} thousandSeparator={true}/>
          </h5>
        }



        {
          JSON.stringify(this.state.schoolfeees_object) === '{}'?
          null:
        <h5 style={{color:'black'}}>
        {this.state.selected_schooterm_ngo_name}
        &nbsp;
        {this.state.selectedschool_term_name}
        &nbsp;

        total school requirements: 
        &nbsp;
        <CurrencyFormat value={this.state.schoolfeees_object.total_termlyrequirements} displayType={'text'} thousandSeparator={true}/>

        </h5>
      }

        </Card>

    </Col>

    </Row>


    <Card >

    <h4 style={{color:'green',display:'flex',alignItems:'center',alignSelf:'center'}}>Ngos monthly expenses statistics for {this.state.selected_month_ofexpenses}</h4>
    <FormItem label="Month">
            <Select 
            placeholder="select Month"
            style={{ width: 200 }} 
            value={this.state.selected_month_ofexpenses} 
            onChange={(val)=>{
            this.setState({selected_month_ofexpenses:val})

            this.setState({ngos_array:[]})
            this.setState({ngoexpense_list:[]})
        
            //qry and get new data
            let form_data1 = new FormData();
            form_data1.append('action', 'newdata');
            form_data1.append('month', val);

            axios.post(serverconfig.backendserverurl+`/customqueries/getexpense_statistics`, form_data1,{
              headers: {
                'content-type': 'multipart/form-data'
              }
              })
            .then(res => {
                console.log(JSON.parse(res.data.months))
                this.setState({
                  currentyearmonths_expenses:JSON.parse(res.data.months) 
                })
                
                var array_list=JSON.parse(res.data.report)
                var ng_list=[]
                var exp_list=[]
        
                //loop through list
                array_list.map((item)=>{
                  ng_list.push(item.ngo_name)
                  exp_list.push(item.totalof_expenses)
                })
        
                this.setState({ngos_array:ng_list})
                this.setState({ngoexpense_list:exp_list})
        
            })
        
          }}

            showSearch
            optionFilterProp="children"
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onSearch={this.onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }                     
            >
              {
                this.state.currentyearmonths_expenses.map((item)=>{
                  return (<Option value={item}>{item}</Option>);
                })
              }
              
            </Select>
        </FormItem>
        
        <Bar data={transchartdata_expenses} options={transchartoptions} />
     </Card>


      </div>

       :
       null}


       <Modal              
          visible={this.state.showprintermodal}
          title="NGO Registration"
          width={600}
          onCancel={()=>{this.setState({showprintermodal:false})}}
      
          footer={[
            <Button key="back" onClick={()=>{this.setState({showprintermodal:false})}}>
              Cancel
            </Button>
            ]}
            >
             <NGOCreateForm />
                 
        </Modal>
      </div>

      )
  }


  }
}

export default AdminIndex; 
