
import React from 'react'
import { connect } from 'react-redux'
import { Layout,Avatar, Menu, Breadcrumb,Button,Row, Col, Divider,Card,Dropdown,Switch,
    message, Tooltip,InputNumber,Modal,Image,notification,Alert} from 'antd';
import { Drawer, List,Affix } from 'antd';

import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LaptopOutlined,
  NotificationOutlined,
  SettingOutlined,
  AuditOutlined,
  IdcardOutlined,
  BarsOutlined,
  ShopOutlined,
  DollarOutlined,
  PicLeftOutlined,
  LogoutOutlined,
  EditOutlined, 
  EllipsisOutlined,
  HomeOutlined,
  SnippetsOutlined,
  KeyOutlined,
  FilePdfFilled,
  RightCircleFilled,
  DollarCircleOutlined,
  AccountBookOutlined,
  FilePptOutlined,
  PayCircleOutlined,
  BarChartOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
  BankFilled,
  AndroidOutlined,
  DeploymentUnitOutlined,
  GroupOutlined,
  MessageFilled,
  LeftCircleFilled,
  HomeFilled,
  ArrowLeftOutlined
} from '@ant-design/icons';

import { NavLink } from 'react-router-dom'
import { PageHeader, Descriptions } from 'antd';
import { Link,withRouter} from 'react-router-dom';
import * as actions from '../../store/actions/auth'
import axios from 'axios'
import UserAccountUpdateForm from '../components/UserAccountUpdateForm'
import WrappedPasswordResetForm from './PasswordResetForm'
import * as serverconfig from '../../views/serverconn'
import * as serversocketconfig from '../../views/serversocketconfig'

import logo from '../../assets/Pitech-logoconv.png';
import tickAudio from '../../assets/sounds/that-was-quick.mp3';
import UIfx from 'uifx';
import ReconnectingWebSocket from 'reconnecting-websocket';
import {useHistory} from 'react-router-dom';
import { Badge, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem } from 'reactstrap';

var CryptoJS = require("crypto-js");


const tick = new UIfx(tickAudio,  {
  volume: 1.0, // number between 0.0 ~ 1.0
  throttleMs: 100
});

var sponser_ngotransfernotificationsocket = new ReconnectingWebSocket(serversocketconfig.socketurl+'/ws/sponser_ngotransfernotification/');


const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const style = { background: '#0092ff', padding: '8px 0' };
const { Meta } = Card;


const pStyle = {
  fontSize: 16,
  lineHeight: '24px',
  display: 'block',
  marginBottom: 16,
};

const DescriptionItem = ({ title, content }) => (
  <div
    className="site-description-item-profile-wrapper"
    style={{
      fontSize: 14,
      lineHeight: '22px',
      marginBottom: 7,
    }}
  >
    <p
      className="site-description-item-profile-p"
      style={{
        marginRight: 8,
        display: 'inline-block',
      }}
    >
      {title}:
    </p>
    {content}
  </div>
);

var bizuserid= ''
var token= ''

class SaccoLayout extends React.Component{

  state = {
    collapsed: false,
    theme: 'dark',
    current: '1',
    titlecolor: '#fff',
    titlevisibility:"visible",
    userprofile:{},
    companyprofile:{},
    isToggle: null,
    visible: false,
    resetmodalvisible:false,
    updateformvisible:'none',
    userrights:{},
    numberofnotifications:0,
    notificationinfo:'',
    notifications:[],
    shownotificationmodal:false,
  };


  // show the profile modal
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  // update user profile
  showUpdateForm=()=>{
    this.state.updateformvisible=="none"?
    this.setState({
      updateformvisible: "block",
    })
    :
    this.setState({
      updateformvisible: "none",
    })    
  }


  /////////////////////////////////////////////
  //PASSWORD RESET MODAL METHODS BEGIN HERE

  showpasswordresetModal = () => {
    this.setState({
      resetmodalvisible: true,
    });
  };

  //Item addition success
  onresetdrawerClose = () => {
    this.setState({ resetmodalvisible: false });

  };


//component did mount
  componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    if(localStorage.getItem("bizuserid")){
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
       bizuserid= ''
    }



    let form_data = new FormData();
    form_data.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
    })

    //get the company profile here
    axios.get(`${serverconfig.backendserverurl}/api/sponsor/${1}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

    axios.get(serverconfig.backendserverurl+`/api/accountrights_ge/?user=${bizuserid}`)
    .then(res => {
        this.setState({
          userrights:res.data[0]
        })
    })


    //destroy all previous notifications
    notification.destroy()

    axios.get(`${serverconfig.backendserverurl}/api/sponsernotifications?read=${false}`)
    .then(res => {

        console.log(res.data)
      //now loop through the tasks
      res.data.map(
        (ntfy)=>{
          notification.open({
            top:60,
            duration:0,
            message:  String(ntfy.title),
            description:String(ntfy.message),
            icon: <MessageFilled style={{ color: "green" }} />,
          })
          
          this.setState({numberofnotifications:Number(this.state.numberofnotifications)+1})
        })

        this.setState({notifications:res.data})

    })


    //observer the socket changes now
    sponser_ngotransfernotificationsocket.onopen = () => {
      console.log("onopen for money notification");
    // connection opened
    };

    sponser_ngotransfernotificationsocket.onmessage = async (e) => {
        notification.destroy()

        // a message was received
        console.log("onmessage for stock",e.data);
        const data = JSON.parse(e.data);

        this.setState({numberofnotifications:Number(this.state.numberofnotifications)+1})

        //play notification
        tick.play();

        notification.open({
          top:60,
          duration:0,
          message:  data.title,
          description:data.message,
          icon: <MessageFilled style={{ color:"green" }} />,
        })

    };

  }

  //method for changing color theme 
  changeTheme = value => {
    this.setState({
      theme: value ? 'dark' : 'light',
    });

    this.setState({
      titlecolor: value ? '#fff' : '#A9A9A9',
    });
    

  };

  //method for collupsing of the menu
  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({ collapsed });
    this.setState({titlevisibility: collapsed ? "hidden" :"visible"});
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });

    this.setState({titlevisibility:"hidden"});

  };


   menu = (
    <Menu >
      <Menu.Item key="1" onClick={this.props.logout}>
        <LogoutOutlined />
        Log out
      </Menu.Item>
      <Menu.Item key="2" onClick={this.showDrawer}>
        <UserOutlined />
        View Profile
      </Menu.Item>
      <Menu.Item key="3" onClick={this.showpasswordresetModal}>
        <KeyOutlined />
        Change Password
      </Menu.Item>
    </Menu>
  );

   render(){
    return (
      <div>
        {
         this.props.isAuthenticated ?
         <Layout style={{ minHeight: '100vh' }} >
         <Sider
          collapsedWidth="0"
          collapsed={this.state.collapsed}
          onCollapse={this.onCollapse} 
          theme={this.state.theme}
          breakpoint="lg"
          onBreakpoint={broken => {
            console.log(broken);
          }}
          zeroWidthTriggerStyle={{display:'none'}}
          >
            <br></br>
           <div className="logo" style={{ paddingLeft: 30}} mode="inline">
           <Row>

             <Col span={6}>
{/*               <Avatar size={40} icon={<UserOutlined />} alt="Logo" src={this.state.companyprofile.company_logo} />
        */}      
                
             <Image
              width={100}
              src={this.state.companyprofile.company_logo}    
              />
              <h6 style={{display:'flex',color:'white',margin:3}}>{this.state.companyprofile.company_name}</h6> 

             </Col>
{/*             <Col span={18}><NavLink to='/'><h5 style={{color:this.state.titlecolor,paddingTop: 10,visibility:this.state.collapsed?"hidden":"visible" }}>{this.state.companyprofile.company_name}</h5></NavLink></Col>
      */}           </Row>
           <br/>
           </div>
   
           <Menu 
           theme={this.state.theme}
           onClick={this.handleClick}
           defaultSelectedKeys={['1']} 
           mode="inline">

{/*            <SubMenu
               key="sub1"
               title={
                 <span>
                   <HomeOutlined />
                   <span>Home</span>
                 </span>
               }
             >
            <Menu.Item key="4" >
              <RightCircleFilled />
              <NavLink to='/admin/'><span>Home</span></NavLink>
            </Menu.Item>
            </SubMenu> */}

            {
             this.state.userrights.can_view_ngos===true?
            <SubMenu
            key="sub2"
            title={
              <span>
                <GroupOutlined />
                <span>NGOs</span>
              </span>
            }
           >
            <Menu.Item key="5"><NavLink to='/ngos/'> <RightCircleFilled /> NGOs</NavLink></Menu.Item>

           </SubMenu>
           :null}

          {
            this.state.userrights.can_view_ge_transactions===true?
           <SubMenu
            key="sub002"
            title={
              <span>
                <DollarCircleOutlined />
                <span>Transactions</span>
              </span>
            }
           >
            <Menu.Item key="005"><NavLink to='/transactions/'> <RightCircleFilled />GE-NGO Transactions</NavLink></Menu.Item>
           </SubMenu>
           :
           null
           }

          {
            this.state.userprofile.managerial_account===true?
            <SubMenu
            key="sub22"
            title={
              <span>
                <UserOutlined />
                <span>Users</span>
              </span>
            }
           >
            <Menu.Item key="05"><NavLink to='/allusers/'> <RightCircleFilled /> User Accounts</NavLink></Menu.Item>
           </SubMenu>
            :
            null
          }


          <SubMenu
            key="sub5"
            title={
              <span>
                <FileOutlined />
                <span>Reports</span>
              </span>
            }
          >
            {this.state.userrights.can_view_ge_transactions===true?
              <Menu.Item key="3888"><NavLink to='/edenngotransactionsreport/'> <RightCircleFilled /> Eden- Ngo Transactions</NavLink></Menu.Item>
            :null}

            {this.state.userrights.can_view_ngo_transactions_report===true?
              <Menu.Item key="05333"><NavLink to='/ngongotransacyionsreport/'> <RightCircleFilled /> Ngo- Ngo Transactions</NavLink></Menu.Item>
            :null}
 
            {this.state.userrights.can_view_childrens_report===true?
              <Menu.Item key="8897"><NavLink to='/childrensreport/'> <RightCircleFilled /> Children Report</NavLink></Menu.Item>
              :null}

            {this.state.userrights.can_view_guardians_report===true?
              <Menu.Item key="88335597"><NavLink to='/guardiansreport/'> <RightCircleFilled /> Guardians Report</NavLink></Menu.Item>
              :null}

            {this.state.userrights.can_view_expense===true?
              <Menu.Item key="885597"><NavLink to='/expensesreport/'> <RightCircleFilled /> Expenses Report</NavLink></Menu.Item>
              :null}
              
            {this.state.userrights.can_view_school_fees_budget===true?
              <Menu.Item key="8833577597"><NavLink to='/schoolfeesreport/'> <RightCircleFilled /> School fees budget Report</NavLink></Menu.Item>
              :null}

            {this.state.userrights.view_schools_report===true?
              <Menu.Item key="88332222577597"><NavLink to='/schools_report/'> <RightCircleFilled /> Schools Report</NavLink></Menu.Item>
              :
              null}

          </SubMenu>    

           </Menu>
           <br></br>
           <br></br>
           <br></br>
           <br></br>
           <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
           <br></br>
              <Switch
                 checked={this.state.theme === 'dark'}
                 onChange={this.changeTheme}
                 checkedChildren="Dark"
                 unCheckedChildren="Light"
               />
           <br></br>
           </div>

         </Sider>
         <Layout className="site-layout">
   
 
            <PageHeader
              style={{backgroundColor: "white"}}
              ghost={true}
              tags={React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {className: 'trigger',
              onClick: this.toggle,
            })}
              extra={[
                <Badge  onClick={()=>{
                  this.setState({shownotificationmodal:true})
              
                }}  pill color="danger">{this.state.numberofnotifications}</Badge>,

                <Avatar size={30} icon={<UserOutlined />} alt="User Pic" src={serverconfig.backendserverurl+this.state.userprofile.profile_pic} />,
                <Dropdown.Button overlay={this.menu}>
                Hi, {this.state.userprofile.username}
                </Dropdown.Button>
              ]}
              >
          </PageHeader>
        

           <Content style={{ margin: '0 16px' }}>
             <br></br>
             <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
              <br></br>

              <div style={{display:'flex',flexDirection:'row'}}>
              
              {
              this.props.location.pathname==='/'?
              null:
              <Affix>

              <Tooltip title="Go to home">
              <HomeOutlined style={{display:'flex',fontSize: 30,marginRight:30}} onClick={()=>{
                this.props.history.push('/')      
                }}/>
              </Tooltip>
              </Affix>
               }


                {
                  this.props.location.pathname==='/'?
                  null:
                  <Affix>
                  <Tooltip title="Go back">
                  <ArrowLeftOutlined style={{display:'flex',fontSize: 30,marginRight:5}} onClick={()=>{
                    this.props.history.goBack()      
                    }}/>
                  </Tooltip>
                  </Affix>
                }


{/*              <Affix>

              <Tooltip title="Next page">
              <RightCircleFilled style={{display:'flex',fontSize: 30,marginLeft:5}} onClick={()=>{
                this.props.history.goForward()    


              }}/>
              </Tooltip>
              </Affix> */}

              </div>
              <br></br>

             {this.props.children}
             </div>
           </Content>
           <Footer style={{ textAlign: 'center' }}>System Design ©2021 Created by PI-Tech</Footer>
         </Layout>
       </Layout>
         :
         <Layout style={{ minHeight: '100vh' }}>
         <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
           <div className="site-layout-background" style={{padding: 24, minHeight: 380,display: 'flex',  justifyContent:'center', alignItems:'center' }}>
             {this.props.children}
           </div>
         </Content>
         <Footer style={{ textAlign: 'center' }}>System Design ©2021 Created by PI-Tech</Footer>
         </Layout>
        }

        <Drawer
          title="Account Profile"
          width={520}
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <p style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>      
             <Avatar
              size={100}
              icon={<UserOutlined />}
              alt="client logo"
              src={serverconfig.backendserverurl+this.state.userprofile.profile_pic}
              />
          </p>
          <Row>
            <Col span={12}>
              <DescriptionItem title="User Name" content={this.state.userprofile.username} />
            </Col>
            <Col span={12}>
              <DescriptionItem title="Email" content={this.state.userprofile.email} />
            </Col>
          </Row>         
          <p style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>      
             <Button type="primary" onClick={this.showUpdateForm}>
               {
                 this.state.updateformvisible=="none"?
                 "Open Update Form"
                 :
                 "Close Update Form"
               }
               
               </Button>
          </p>
          
          <div style={{display:this.state.updateformvisible}}>
           <UserAccountUpdateForm accountID={this.state.userprofile.id}/>       

          </div>

        </Drawer>


        <Drawer
          title="Password Change Form"
          width={520}
          placement="right"
          closable={false}
          onClose={this.onresetdrawerClose}
          visible={this.state.resetmodalvisible}
        >
            <WrappedPasswordResetForm />

        </Drawer>


        <Modal            
          visible={this.state.shownotificationmodal}
          title="Notifications"
          onCancel={()=>{
            this.setState({shownotificationmodal:false})

            //mark them as read
            this.state.notifications.map((item)=>{
              let form_data = new FormData();
              form_data.append('read', true);

              axios.put(serverconfig.backendserverurl+`/api/sponsernotifications/${item.id}/`, form_data, {
                headers: {
                  'content-type': 'multipart/form-data'
                }
              })
              .then(res => {
                console.log(res.data)
              })

            })

          }}
      
          footer={[
            <Button key="back" onClick={()=>{
              this.setState({shownotificationmodal:false})

              console.log(this.state.notifications)
              //mark them as read
              this.state.notifications.map((item)=>{
                console.log(item.id)
                let form_data = new FormData();
                form_data.append('read', true);

                axios.put(serverconfig.backendserverurl+`/api/ngonotification/${Number(item.id)}`, form_data, {
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                })
                .then(res => {
                  console.log(res.data)
                })

              }) 

            }}>
              Cancel
            </Button>
            ]}
            >

          {this.state.notifications.map(
            (note)=>{
              return(<Alert closable style={{margin:6}} showIcon message={note.title} description={note.message} type="success" />);
            }

          )}

        </Modal>  

      </div>
  
  );

  }

    
}


const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(
      actions.logout(),
      )
  }
}


export default withRouter(connect(null,mapDispatchToProps)(SaccoLayout)) ;

  
