import React from 'react';
import { Form, Input, Button,Select,message,Spin,Switch,InputNumber,Card,Result,DatePicker} from 'antd';
import { Table,Collapse,Popover,Popconfirm,Descriptions,Modal } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import * as serverconfig from '../../views/serverconn'
import moment from 'moment';
import uuid from 'react-uuid'
import CurrencyFormat from 'react-currency-format';
import * as reactstrp from 'reactstrap';

import { SearchOutlined,LoadingOutlined,DeleteOutlined,FundViewOutlined,WalletFilled,EditOutlined } from '@ant-design/icons';
var CryptoJS = require("crypto-js");

const dateFormat = 'DD/MM/YYYY';

const FormItem=Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var bizuserid=''


class TransactionCreateForm extends React.Component {

  state = {
    ngo_name:'',  
    licence:'',
    companyemail:'',
    ngo_logo:null,   
    companyphone:'',
    boxnumber:'',
    accountslimit:0,
    branchname:'',
    companyphonetwo:'',
    companyphonethree:'',
    website:'',
    address:'',
    datarequested:true,  
    safebalance:0,
    sharevalue:0,
    retainedearnings:0,
    packages:[],
    package:'',
    mobilemoneygatewaycode:'',
    mobilemoneyreceiver_number:'',
    mobilemoneyreceiver_number:'',
    bankaccount:'',
    ngo_controller:false,

    ngos:[],
    selected_ngo:'',
    transaction_channel:'',
    transfer_amount:0,
    remarks:'',
    reason:'',

    transfer_list:[],
    itemupdate:false,
    itemnumber:0,
    selected_ngo_object:{},
    selectedupdateitem:{},
    companyaccounts: [],
    selectedbankaccount:'',
    ngos_nofiction_loop:[],
    selected_ngo_to_notifiy:'',
    selected_ngo_to_notify_object:{},
    currencies:[],
    selected_curreny:'',
    proof_of_payment_attachment:null,
    datasubmittedsuccessfully:false,
    date:moment().format(dateFormat).toString(),

  }


  componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

   if(localStorage.getItem("bizuserid")){
    bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
  }else{
      bizuserid= ''
  }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/ngos/`)
    .then(res => {
        this.setState({
          ngos:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/currencies/`)
    .then(res => {
        this.setState({
          currencies:res.data
        })
        this.setState({datarequested:false})
    })

}

onBlur=()=> {
  console.log('blur');
}

 onFocus=()=>{
  console.log('focus');
}

onSearch=(val)=> {
  console.log('search:', val);
}

//calculateoverall totak
calculateoveralltotal=()=>{
  var tal=0
  this.state.transfer_list.map((item)=>{
    tal+=Number(item.transfer_amount)
  })
   return tal;
}


  //submit button pressed
  handleFormSubmit=(event) =>{

    if (this.state.transfer_list.length>0){
      this.setState({datarequested:true})

      let form_data1 = new FormData();
      axios.post(serverconfig.backendserverurl+'/customqueries/get_lastsponsorpayment_batch_no', form_data1, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        if(res.data.message==="success"){
          var batchno=res.data.batchno

          let form_data = new FormData();
          form_data.append('transfer_list',JSON.stringify(this.state.transfer_list) );
          form_data.append('overalltotal',this.calculateoveralltotal());
          form_data.append('notificationloop',JSON.stringify(this.state.ngos_nofiction_loop));
          form_data.append('user',bizuserid);
          form_data.append('batchno',batchno);
          form_data.append('date',this.state.date);
          axios.post(serverconfig.backendserverurl+'/customqueries/sponsor_transfer', form_data, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          })
          .then(res =>{

            if(res.data.message==="success" && this.state.proof_of_payment_attachment!=null){
              var transactions=JSON.parse(res.data.transactions)

              //loop through transactions and attach proof_of_payment_attachment
              transactions.map((item)=>{
                let form_data2 = new FormData();

                this.state.proof_of_payment_attachment==null?
                console.log("No file")
                :
                form_data2.append('proof_of_payment_attachment', this.state.proof_of_payment_attachment, this.state.proof_of_payment_attachment?this.state.proof_of_payment_attachment.name:"");

                axios.put(serverconfig.backendserverurl+`/api/proofofpaymentupload/${item}/`, form_data2, {
                  headers: {
                    'content-type': 'multipart/form-data'
                    }
                  })
                .then(res =>{
                  console.log("successful image upload")
                })


              })
              
            }else{
              console.log("no file to attach")
            }

            this.setState({datarequested:false})
            this.setState({datasubmittedsuccessfully:true})

          }
          )
          .catch(error => console.log(error))
        }
      }
       
      )
      .catch(error => console.log(error))

    
    }else{
      message.info("Transfer List empty")

    }
  }

  //list total
  calculatelisttotal=()=>{
    var total=0;
    this.state.transfer_list.map((item)=>{
      total+=Number(item.transfer_amount)
    })
    return total;
  }


  addtolist=(event) =>{
    if (this.state.itemupdate===true){
      const elementsIndex = this.state.transfer_list.findIndex(element => element.key == this.state.selectedupdateitem.key )

      let newArray = [...this.state.transfer_list]

      newArray[elementsIndex] = {...newArray[elementsIndex], 
        transfer_amount:this.state.transfer_amount,
      }

      this.setState({
        transfer_list: newArray,
        });

        this.setState({selected_ngo_object:{}});
        this.setState({transfer_amount:0})
        this.setState({reason:''})
        this.setState({selected_ngo:''});    
        this.setState({itemupdate:false})

        this.setState({companyaccounts:[]})
        this.setState({selectedbankaccount:''})
  

    }else{

    if( this.state.selectedbankaccount===""){
      message.info("Please select bank account")
    }else{

      this.setState({itemnumber:(Number(this.state.itemnumber)+1)})

      const newreceiptitem={
        key:uuid(),
        itemnumber:(Number(this.state.itemnumber)+1),
        ngo_name:this.state.selected_ngo_object.ngo_name,
        ngo:this.state.selected_ngo_object.id,
        transfer_amount:Number(this.state.transfer_amount),
        reason:this.state.reason,
        selectedbankaccount:this.state.selectedbankaccount,
        selected_curreny:this.state.selected_curreny,
       }
  
      //add to the receipt item list
      this.setState({
        transfer_list: [...this.state.transfer_list, newreceiptitem]
      });
  
      this.setState({selected_ngo_object:{}});
      this.setState({transfer_amount:0})
      this.setState({reason:''})
      this.setState({selected_ngo:''});
      this.setState({companyaccounts:[]})
      this.setState({selectedbankaccount:''})
      this.setState({selected_curreny:''})
    } 

  }

  }


  //remove item
  removeItem=(id)=>{
    console.log(id)
    this.setState({ transfer_list:  [...this.state.transfer_list.filter(todo => todo.key
      !==id)]});
  }


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title="Successfully submitted transaction "
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
        );

      }else{

        const receiver_columns = [
          {
            title: 'Ngo name',
            dataIndex: 'ngo_name',
            key: 'key',
          },
          {
            title: 'Amount to receive',
            dataIndex: 'transfer_amount',
            key: 'key',
            render: (text,record) =><p><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}/></p>
  
          },
          {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'key',
          },
          {
            title: 'Currency',
            dataIndex: 'selected_curreny',
            key: 'key',
          },
          
          {
            title: 'Action',
            dataIndex: 'key',
            key: 'key',
            render: (text,record) =>
            <p>
            <Popover content={<p>Click here to edit</p>} title="Edit,View">
             <FundViewOutlined style={{color:'blue'}}  onClick={()=>{
                this.setState({itemupdate:true})
  
                this.setState({transfer_amount:record.transfer_amount})
                this.setState({reason:record.reason})
                this.setState({selected_ngo:record.ngo})
                this.setState({selectedbankaccount:record.selectedbankaccount})
  
                axios.get(`${serverconfig.backendserverurl}/api/ngos/${record.ngo}`)
                .then(res => {  
                    this.setState({
                        selected_ngo_object: res.data
                    })
                })  
  
                this.setState({selectedupdateitem:record})
    
              }}/>
            </Popover>
            &nbsp;&nbsp;&nbsp;
            <Popover content={<p>Click here to delete client</p>} title="Delete">
            <Popconfirm title="Sure to delete?" onConfirm={() => this.removeItem(text)}>
              <DeleteOutlined style={{color:'red'}} />
             </Popconfirm>
            </Popover>
            </p>,
          }
    
        ];
  
  
        return (
          <div >
              <Table 
                scroll={{ x: 1000 }}
                columns={receiver_columns}
                pagination={{showQuickJumper:true,showSizeChanger:true }}
                dataSource={this.state.transfer_list} bordered/>
                <br></br>
                <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>
                              <h4 style={{color:'green'}}>Add a new receiver</h4>
                              <Form
                              onFinish={(event) => this.addtolist(event)}
                              >
                              <FormItem label="NGO to receive money"
                                >
                                <Select 
                                placeholder="Select NGO" 
                                style={{  }} 
                                value={this.state.selected_ngo}
                                onChange={(val)=>{
                                  //rest bank accounts
                                  this.setState({companyaccounts:[]})
                                  this.setState({selectedbankaccount:''})
  
                                  this.setState({selected_ngo:val})
  
                                  //selected ngo object set
                                  axios.get(`${serverconfig.backendserverurl}/api/ngos/${val}`)
                                  .then(res => {  
                                      this.setState({
                                          selected_ngo_object: res.data
                                      })
                                    })
  
                                  axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?Ngo=${val}`)
                                  .then(res => {
                                      this.setState({
                                          companyaccounts:res.data
                                      })
                                  })
  
                                }} 
                                showSearch
                                optionFilterProp="children"
                                onFocus={this.onFocus}
                                onBlur={this.onBlur}
                                onSearch={this.onSearch}
                                                      
                                >
                                  {this.state.ngos.map(
                                    (accnt)=>
                                    {
                                      if (accnt.ngo_controller===true){
                                        return (
                                          <Option value={accnt.id}>{accnt.ngo_name} | {accnt.mobilemoney_contact} | {accnt.bankaccount}</Option>
                    
                                        );
                                      }
                                    }    
                                    )
                                    
                                  }
                                </Select>
  
                              </FormItem> 
  
  
                              {
                              this.state.companyaccounts.length>0?
                                <FormItem label="Select bank account"
                                >
                                <Select 
                                placeholder="bank account" 
                                style={{ }} 
                                value={this.state.selectedbankaccount}
                                onChange={(val)=>{
                                  this.setState({selectedbankaccount:val})
                                }} 
                                showSearch
                                optionFilterProp="children"
                                onFocus={this.onFocus}
                                onBlur={this.onBlur}
                                onSearch={this.onSearch}                
                                >
                                  {
                                    this.state.companyaccounts.map((item)=>{
                                      return (<Option value={item.id}>{item.account_number} | {item.bank_name}</Option>);
                                    })
                                  }
                                
                                </Select>
                              </FormItem> 
                                :
                                null
                              }
  
                              
                              <FormItem label="Amount"
                              >
  
                              <Input 
                              name="transfer_amount" 
                              placeholder="transfer amount" 
                              value={this.state.transfer_amount}
                              onChange={(val)=>{this.setState({transfer_amount:val.target.value})}} 
                              type='number'
                                />
  
                              </FormItem> 
                    
  
                              <FormItem label="Reason"
                                >
                                    <TextArea
                                      placeholder="Reason for transfer"
                                      autoSize={{ minRows: 2, maxRows: 6 }}
                                      value={this.state.reason}
                                      onChange={(val)=>{this.setState({reason:val.target.value})}}
                                    />
                                </FormItem>
  
                                <FormItem label="Select currency"
                                >
                                <Select 
                                placeholder="Select currency" 
                                style={{  }} 
                                value={this.state.selected_curreny}
                                onChange={(val)=>{
                                  //rest bank accounts
                                  this.setState({selected_curreny:val})
                                }} 
                                showSearch
                                optionFilterProp="children"
                                onFocus={this.onFocus}
                                onBlur={this.onBlur}
                                onSearch={this.onSearch}
                                                      
                                >
                                  {this.state.currencies.map(
                                    (accnt)=>
                                    {
                                    return (
                                      <Option value={accnt.currency_code}>{accnt.currency_code} | {accnt.currency_name} | {accnt.country}</Option>
                                    );
                                   
                                  })
                                    
                                  }
                                </Select>
                              </FormItem> 


  
                              <FormItem>
                                <Button type="primary" htmlType="submit" >{this.state.itemupdate?" Update":" Add"} </Button>
                              </FormItem>
                              </Form>
  
                            </th>
                            <th>
                            <h4 style={{color:'green'}}>Add ngo to be notified.</h4>
  
                              {
                                this.state.ngos_nofiction_loop.map((item)=>{
                                  return (
                                  <h4>
                                    {item.ngo_name} &nbsp; 
                                    <Popover content={<p>Click here to delete</p>} title="Delete">
                                    <Popconfirm title="Sure to delete?" onConfirm={() => {
                                         console.log(item.key)
                                         this.setState({ ngos_nofiction_loop:  [...this.state.ngos_nofiction_loop.filter(todo => todo.key!==item.key)]});
                                    }}>
                                      <DeleteOutlined style={{color:'red'}}/>
                                    </Popconfirm>
                                    </Popover>
                                  </h4>)
                                })
                              }
  
                           
                            <FormItem label="NGO to receive notifictaion of transaction"
                                >
                                <Select 
                                placeholder="Select NGO" 
                                style={{  }} 
                                value={this.state.selected_ngo_to_notifiy}
                                onChange={(val)=>{
                                  //selected ngo object set
                                  axios.get(`${serverconfig.backendserverurl}/api/ngos/${val}`)
                                  .then(res => {  
                                      this.setState({
                                          selected_ngo_to_notify_object: res.data
                                      })
                                    })
                                    this.setState({selected_ngo_to_notifiy:val})
                                }} 
                                showSearch
                                optionFilterProp="children"
                                onFocus={this.onFocus}
                                onBlur={this.onBlur}
                                onSearch={this.onSearch}
                                                      
                                >
                                  {this.state.ngos.map(
                                    (accnt)=>
                                    {
                                      if (accnt.ngo_controller===false){
                                        return (
                                          <Option value={accnt.id}>{accnt.ngo_name}</Option>
                                        );
                                      }
                                    }    
                                    )
                                  }
                                </Select>
                              </FormItem> 
  
                              <FormItem>
                                <Button type="primary" onClick={()=>{
                                  const newreceiptitem={
                                    key:uuid(),
                                    ngo_name:this.state.selected_ngo_to_notify_object.ngo_name,
                                    ngo:this.state.selected_ngo_to_notify_object.id,
                                  }
                                  //add to the receipt item list
                                  this.setState({
                                    ngos_nofiction_loop: [...this.state.ngos_nofiction_loop, newreceiptitem]
                                  });
  
                                }}  >Add  </Button>
                              </FormItem>
  
                            </th>
                          </tr>
                        </thead>
                      
                 </reactstrp.Table> 
  
                <br></br>
                <h3>List Total Amount: {<CurrencyFormat value={this.calculatelisttotal()} displayType={'text'} thousandSeparator={true}/>}  For ( {this.state.transfer_list.length} ) Receivers</h3>
                <br></br>
  
              <h3>{"Data submission Section"}</h3>
  
              <FormItem label="Proof of payment attachemnt" style={{marginRight:3}}>
                <Input type="file" accept=".pdf" placeholder="Proof of payment Form" 
                onChange={(val)=>{
                  var kilbyes=val.target.files[0].size/1024
                  if (kilbyes>Number(1024)){
                    message.error("File is greater than "+"1024"+" Kbs or 1 mb .It will not be uploaded.")
                  }else{
                    this.setState({proof_of_payment_attachment:val.target.files[0]})
                  }
                  
                  }}/>
             </FormItem> 

             <FormItem label={"Date ( "+this.state.date+" )"}>
                  <DatePicker onChange={(date, dateString) => this.setState({ date: dateString})} format={dateFormat} />
              </FormItem>
              
  
  
              <FormItem >
                <Button type="primary" htmlType="button" onClick={()=>{
                  this.handleFormSubmit()
  
                }}>Submit Transfers ({this.state.transfer_list.length}) </Button>
              </FormItem>
  
          </div>
        );


        
      }





 
    }
  }

}

export default TransactionCreateForm;
